.mission-component {
    display: flex;
    align-items: center;
    padding: 100px;
    height: auto;
    background-color: #fff;
    margin-top: 5%;
    margin-bottom: 5%;
}

.mission-text {
    max-width: 50%;
}

.mission-text h2 {
    color: #f97316;
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: 900;
}

.mission-text p {
    padding-right: 50px;
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
    text-align: justify;
}

.mission-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    transform: scale(1.15);
}

@media (max-width: 768px) {
    .mission-component {
        flex-direction: column;
        text-align: center;

        padding: 10%;

    }
    .mission-text, .mission-image {
        max-width: 100%;
    }

    .mission-text p {
        padding: 0%;
        font-size: 0.7rem;
        width: 100%;
        }

}
