/* ExpertiseSection.css */

.container-work p{
  color: black;
}

.expertise-section {
    padding: 50px 0;

    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.75) 30%, rgba(255, 255, 255, 0.75) 70%, rgba(255, 255, 255, 1) 100%), url('../img/4.jpg');
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  
  .expertise_icon_title {
    width: 100%;
  }

  .expertise_icon_title img {
    margin: 0 !important;
  }
  

  .container-work {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
  }
  .container p {
    color: black;
  }
  
  .expertise-section h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #FF6F00;
    font-weight: 700;
  }
  
  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
  }
  
  .service-card {
    border-radius: 8px;
    width: 20%;
    padding: 20px;
    text-align: center;
  }
  
  
  .service-card img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }
  
  .service-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #FF6F00;
  }
  
  .service-card p {
    font-size: 1rem;
    color: black;
    line-height: 1.5;
  }

  @media screen and (max-width: 768px) {
    .service-card{
      width: 40%;
      padding: 0;
    }
    .service-card p{
       font-size: 0.7rem;
    }
    .expertise-section h2{
      font-size: 2rem;
      width: 100%;
      }
    .container-work p{
      color: black;
    }
    .expertise_icon_title img{
      width: 100%;
    }
  }
