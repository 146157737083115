/* Estilos generales */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evitar scroll horizontal */
  width: 100%;

  background-color: #FFF; /* Fondo oscuro general */
 
  background-color: #333; /* Fondo oscuro general */
  scroll-behavior: smooth; /* Hacer que el desplazamiento sea suave */

}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.App {
  font-family: 'Poppins', sans-serif;
  background-color: rgb(27, 53, 91);
  color: #fff; /* Cambiado a blanco para mejor contraste con fondo oscuro */
  min-height: 100vh;
}





/* Logo */
.logo {
  height: 60px; /* Ajuste del tamaño del logo para mejor alineación */
  margin-left: 10px;
}

/* Navigation buttons */
.nav-buttons {
  display: flex;
  gap: 20px;
  margin-right: 20px;
}

.nav-buttons button {
  background: none;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  position: relative;
  transition: color 0.3s ease;
}

.nav-buttons button::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #FF7300;
  transition: width 0.3s ease, left 0.3s ease;
}

.nav-buttons button:hover::after {
  width: 100%;
  left: 0;
}

.nav-buttons button:hover {
  color: #FF7300;
}

/* Asegurar que el contenido no se superponga con el header */
.section {
  padding-top: 140px; /* Incrementa el padding-top para garantizar que el contenido no se superponga con el header */
}



/* Sección Our Services */


.our-services h2 {
  font-size: 3.5rem;
  color: #FF7300;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
}

/* Contenedor de tarjetas */
.our-service-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Tarjetas */
.our-service-card {
  position: relative;
  overflow: hidden;
  min-height: 400px;
  transition: transform 0.3s ease;
  border: 1px solid #000;
  background-color: #fff; /* Fondo blanco para mayor contraste */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.our-service-card:hover {
  transform: scale(1.02);
}

/* Imagen de fondo */
.our-service-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: brightness(0.5);
}

.our-service-card:hover img {
  transform: scale(1.1);
  filter: brightness(0.3);
}

/* Texto superpuesto */
.our-service-card h3,
.our-service-card p {
  position: relative;
  color: #333;
  text-shadow: none;
  opacity: 1;
  margin: 10px;
}

.our-service-card h3 {
  font-size: 2rem;
  color: #FF7300;
  text-align: center;
  margin-top: 20px;
}

.our-service-card p {
  font-size: 1.1rem;
  text-align: center;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 900px) {
  .our-service-cards {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
  }

  .our-service-card:nth-child(3) {
    grid-column: span 2;
  }

  .our-services h2 {
    font-size: 2.8rem;
  }
}

@media (max-width: 600px) {
  .our-service-cards {
    grid-template-columns: 1fr;
  }

  .our-services h2 {
    font-size: 2.5rem;
  }
}

/* Contact Section */
.contact-section {
  display: flex;
  justify-content: space-between;
  padding: 50px 50px; /* Aumenta el padding para mantener consistencia con otras secciones */
  background-color:rgba(5, 33, 74);
  min-height: 500px;
}

.contact-section img {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, filter 0.3s ease;
}

.contact-section img:hover {
  transform: scale(1.05);
  filter: brightness(0.85) contrast(1.1);
}

.contact-title {
  color: orange;
  font-weight: bold;
  font-size: 48px;

}

.contact-form {
  width: 50%;
  padding: 30px;
  background-color: rgba(5, 33, 74, 0.9);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  gap: 40px;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #FF7300;
}

.form-group {
  flex: 1;
}

.form-group label {
  color: #fff;
  font-size: 1rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #FF7300;
  border-radius: 5px;
  background-color: rgba(0, 31, 76, 0.8);
  color: #fff;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
}

.submit-button-container {
  text-align: left;
  padding-bottom: 30px;
}

.submit-button {
  background: #FF7300;
  color: #fff;
  border: 2px solid #FF7300;
  padding: 12px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  width: 50%;
}

.submit-button::before {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #FF7300;
  top: 0;
  left: 0;
  transition: width 0.3s ease;
  z-index: -1;
}

.submit-button:hover::before {
  width: 100%;
}

.submit-button:hover {
  color: #fff;
  transform: scale(1.05);
}

/* Responsive Design para Contact Section */
@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
    align-items: center;
    padding: 60px;
  }

  .contact-section img {
    display: none;
  }

  .contact-form {
    width: 100%;
    padding: 20px;
  }
}

footer {
  background-image: url('./img/92.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 100px 20px;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 1;
}

footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.footer-icons a {
  color: #FF7300;
  font-size: 1.8rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer-icons a:hover {
  color: #CE5300;
  transform: scale(1.2);
}

footer p {
  font-size: 1rem;
  margin: 10px 0;
  color: #fff;
}

/* Contact Panel */
.contact-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contact-panel {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
}

.contact-panel h3 {
  margin-bottom: 20px;
  color: #333;
}

.contact-panel p {
  margin-bottom: 10px;
}

.close-button {
  background-color: #FF7300;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
}

.close-button:hover {
  background-color: #CE5300;
}
.slick-slider{
  background-color: #fff;
}

.error-message {
  color: #d8000c;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #d8000c;
  background-color: #ffbaba;
  border-radius: 5px;
}

.success-message {
  color: #4CAF50;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #4CAF50;
  background-color: #dff0d8;
  border-radius: 5px;
}
