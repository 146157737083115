/* AreasOfExpertise.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.Areas-ul{
  list-style: disc;
  padding-left: 20px;
  width: 100%;
}


.expertise-container {
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
    padding: 40px;
    margin: 0 auto;
    background-color: rgb(255, 255, 255);
    position : relative;
    overflow: hidden;
  }

  
  .expertise-text {
    flex: 1;

  }
  
  .expertise-text h2 {
    font-size: 3em;
    color: #FF6F00; /* Adjust to match the color in the image */

    font-weight: 800;
    text-align: center;
  }
  
  .expertise-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
  }
  
  .expertise-image{
    width: 50%;
    transform: scale(1.4);
  }

  .expertise-item p{
    margin: 5%;
    width: 80%;
    text-align: center;
    font-size: clamp(1em, 1.1vw, 1.5em);

    color: black;
  }


  @media screen and (max-width: 768px) {
    .expertise-container{
      flex-direction: column;
      padding: 40px 0;
    }
    .expertise-item p{
      font-size: 0.7rem;
    }
    .expertise-image   {
      width: 100%;
      transform: scale(1.2);
    }


    .Areas-ul li{
      margin: 5%;
    }

  }