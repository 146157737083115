.contact-panel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-panel {
    background: white;
    padding: 20px;
    width: 400px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; /* Alinea el formulario en el centro */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos dentro del formulario */
    width: 100%; /* Asegura que el formulario ocupe todo el ancho disponible */
  }
  
  .form-row {
    display: flex;
    gap: 10px;
  }
  
  .form-group {
    flex: 1;
    margin-bottom: 10px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px;
    width: 100%; /* Para que el botón de envío ocupe todo el ancho disponible */
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  
  .success-message {
    color: green;
    font-weight: bold;
  }
  
  .footer-icons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .footer-icons a {
    font-size: 24px;
    color: #333;
    text-decoration: none;
  }
  